:root {
    --main-white: #fff;
    --main-bg-color-val: 207, 216, 214;
    --main-bg-color: #cfd8d6;
    --main-bg-color-light:#dde3e2;

    --header-bg-color: #f2f1f1;

    
    --color-light-gray:#898989;
    --color-dark-gray:#242424;


    --color-button-primary:#242424;
    --color-button-primary-hover:#353535;


    --gray-background: #dfdfdf;
    --color-non-anchor:#505050;
    --color-anchor:#404040;
    --color-anchor-dark:#202020;
  }